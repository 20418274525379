.splitScreen {
    height: 100vh;
    display: grid;
    grid-template-columns: 2fr 1fr;
}
.btn-group{
    background-color: #fff;
}

.closing {
    
    width: 0% !important;
}

.expand {
    transform: rotate(0deg) !important;
}

.icon-collapse{
    display: inline;
    transform: rotate(-180deg) !important;
}

.w-60.expanded{
    width: 100% !important;
   
}

.threejs canvas{
    width: 100% !important;
    height: 100% !important;
}
.topPane {
    /* background-color: bisque; */
}

.bottomPane {
    /* background-color: blanchedalmond; */
}