/* Changing Editor style css */
/* .upload-container1 {
    margin-top:0 !important ;
}

.blank-design1 .create-steps {
    margin-top: 6rem !important;
} */

/* .tooltip{
    opacity: 1;
} */
.need-help span{
    color: #1b5ade;
    cursor: pointer;
    user-select: none;
}

.need-help span:hover{
    text-decoration: underline;
}

/* .design-container {
    width: 0;
    padding: 0; 
    display: block;
    align-items: center;
    justify-content: space-between;
    max-width:0;
} */

.back-arrow-design span img{
  transform: rotate(-180deg);
}

/*------------------------------------
COMMON CSS
-------------------------------------*/
body {font-family: 'Satoshi-Regular';font-size: 15px;color:#000;line-height:25px;/* -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out;  -ms-transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out;  */margin:0; padding:0;}
html {scroll-behavior: smooth;}
img {border: none;outline: none;max-width: 100%;transition: all 0.35s ease-in-out;-webkit-transition: all 0.35s ease-in-out;-moz-transition: all 0.35s ease-in-out;-ms-transition: all 0.35s ease-in-out;-o-transition: all 0.35s ease-in-out;}
a {transition: all 0.35s ease-in-out;-webkit-transition: all 0.35s ease-in-out;-moz-transition: all 0.35s ease-in-out;-ms-transition: all 0.35s ease-in-out;-o-transition: all 0.35s ease-in-out;outline: medium none;}
a img {border: none;}
ul {margin: 0;list-style-type: none;}
a, a:active, a:focus, a:hover {outline: none;text-decoration: none;color: #000;}
a:hover {color: #000;}
a {transition: all 0.35s ease-in-out;-webkit-transition: all 0.35s ease-in-out;-moz-transition: all 0.35s ease-in-out;-ms-transition: all 0.35s ease-in-out;-o-transition: all 0.35s ease-in-out;text-decoration: none;}
button:focus{outline: none;} /* FF 4-18 */ /* FF 19+ */ /* IE 10+ */ /* FF 4-18 */ /* FF 19+ */ /* IE 10+ */
:focus{border:0;outline:none;}
input::-webkit-input-placeholder,textarea::-webkit-input-placeholder { /* Edge */ opacity:1 }
input:-ms-input-placeholder,textarea:-ms-input-placeholder { /* Internet Explorer 10-11 */opacity:1 }
input::placeholder,textarea::placeholder {opacity:1 }
.design-container{max-width:90%;}
.top-container{width: 100%;justify-content: space-between;align-items: center;}
.logo img{max-width:82%;}
.top-panel{padding:30px 0 0 0;position:relative;z-index: 1;}
/* .footer-social img:hover{margin-top:-8px;} */
.logo{position: relative;z-index: 9;} /* FF 4-18 */ /* FF 19+ */ /* IE 10+ */



@media only screen and (max-width:991px){
.logo{position:static;}
.top-panel{z-index:4;padding: 15px 0 0 0;}
.design-container {max-width: 100%;}
}



/*------------------------------------
UPLOAD (EDITOR)
-------------------------------------*/
.upload-section{background: linear-gradient(#E6E6E6, #C6C6C6);min-height:100vh;}
.signin-link .signin{background:url(../../public/images/user-icon.svg) no-repeat right 10px;font-size: 0;width: 36px;float: right;height: 36px;}
.upload-steps ul{padding:0;margin-left:0;}
.upload-steps ul li{padding:0 35px;display:inline-block;font-size:18px;position: relative;z-index: 2;margin-left: -4px;font-family: 'Satoshi-Medium';}
.upload-steps ul li a{color:#545454;padding: 0 0 7px;cursor: pointer;}
.upload-steps ul li.active a{color:#1B5ADE;border-bottom:1px solid #1B5ADE;}
.upload-steps ul li:after{content:'';background: url(../../public/images/right-arrow6.svg) no-repeat 0 center;width: 19px;height: 13px;top: 9px;right: -10px;position:absolute;}
.upload-steps ul li:last-child:after{content:unset;}
.upload-container1{justify-content: space-between;align-items:flex-start;margin-top: 60px;}
.upload-container1.flex-start-div{justify-content:flex-start;}
.back-arrow img{transform: rotate(-180deg);width: 25px;}
.edit-design a{color:#676767 !important;font-size: 18px;font-family: 'Satoshi-Medium';}
.txt5 span a{color:#1B5ADE;font-size: 15px;}
.edit-design img.edit-icon{max-width: 22px;}
.edit-design img.edit-icon{display: none;}
.next-cncl a,.next-cncl input{color:#9A9A9A;padding:7px 20px;line-height:1;border:2px solid rgba(0,0,0,0);font-family: 'Satoshi-Medium';height: 35px;background: transparent;margin: 0 3px;letter-spacing: 1.93px;}
.next-cncl a.active,.next-cncl a:hover,.next-cncl input.active,.next-cncl input:hover{color:#1B5ADE;border:2px solid #1B5ADE;}
.edit-design{text-align: center;}
.frnt-bck{margin-top:25px;background: #fff;line-height: 1;padding: 10px 0;float: left;}
.browse-bt{margin-top:25px;}
.frnt-bck a{color:#7E7E7E;padding: 0 15px;position:relative;font-family: 'Satoshi-Medium';}
.frnt-bck a:after{content:'';position:absolute;border-right: 1px solid #8f8f8f;height: 100%;top: 0;right: -4px;}
.frnt-bck a:last-child:after{content:unset;}
.frnt-bck a.active{color:#1b5ade !important;font-family: 'Satoshi-Bold';}
.next-cncl{display: flex;flex-wrap: wrap;height: 300px;align-items: first baseline;width:190px;position: relative;z-index: 1;}
.txt4{width:100%;margin-top: 220px;font-family: 'Satoshi-Medium';}
.txt4 span,.txt5 span{color:#1B5ADE;font-family: 'Satoshi-Medium';}
.upload-btn-wrapper {background: #fff;border: 5px solid #FFBFBF;border-radius: 0;padding: 5px;margin: 50px 0 50px;}
.upload-btn-wrapper .btn{color:#7E7E7E;font-size:16px;border:1px dashed #b7b7b7;border-radius: 0;padding:100px 50px 50px 50px;background:url(../../public/images/upload-icon.svg) no-repeat center 50px;background-size: 38px;cursor:pointer;}
.upload-btn-wrapper .btn span{color:#1B5ADE;display: block;}
.upload-btn-wrapper {position: relative;overflow: hidden;display: inline-block;}
.btn {border: 2px solid gray;color: gray;background-color: white;padding: 8px 20px;border-radius: 8px;font-size: 18px;font-weight: bold;}
.upload-btn-wrapper input[type=file] {height: 100%;width: 100%;cursor: pointer;position: absolute;left: 0;top: 0;opacity: 0;}
/*---Review---*/
.upload-review .next-cncl{width:auto;}
.design-review-row1{justify-content: space-between;margin-top: 1%;}
.design-review-col1{width:48%;}
.design-review{width:75%;padding-left:75px;padding-right: .4;}
.review-img{margin: 0 0 20px;}
.review-img img{filter: drop-shadow(0 0px 3px #0003);}
.review-txt{color:#9B9B9B;font-size:18px;font-family: 'Satoshi-Medium';}
.continue-btns a {color: #9A9A9A;padding:8px 20px 10px;line-height: 1;border: 2px solid rgba(0,0,0,0);letter-spacing: 1.93px;font-size: 14px;font-family: 'Satoshi-Medium';}
.continue-btns a.active{color: #1B5ADE;border:2px solid #1B5ADE;}
.continue-btns a.hr1:hover {color:#fff !important;background: #1B5ADE;border: 2px solid #1B5ADE;}
.continue-btns{justify-content: space-between;margin-top: 50px;}
.bt1{padding-left:0 !important;padding-right:0 !important;}
a.bt1:hover{transform: scale(1.03);color: #1B5ADE;}
.next-cncl .download-pdf a{padding: 4px 0 4px 30px;color:#1B5ADE;border:0;background:url(../../public/images/download-icon.svg) no-repeat 0 0;font-size:17px;font-family: 'Satoshi-Medium';display: block;}
.next-cncl .download-pdf a:hover{}
.next-cncl .links2 a{color: #1B5ADE;padding: 0;border: 0;}
.next-cncl .links2 a:hover{text-decoration:underline;}
/*-----create-steps----*/
.create-steps .upload-steps ul{margin-left:0;text-align: center;}
.create-steps{justify-content: center;}
.upload-steps .hd5{font-family: 'Satoshi-Medium';font-size: 33px;margin: 0 0 30px;}
.upload-steps{text-align: center;margin-top: -90px;}
.review.tabbing .upload-steps,.review .upload-steps{margin-top:-128px;}
.design-section{padding: 55px 0 0 0;}
/* .upload-steps2{margin-top: -90px;} */
.review .upload-container1{margin-top: 1%;}
a.download-pdf2{padding: 4px 0 4px 25px;color:#1B5ADE;border:0;background:url(../../public/images/download-icon.svg) no-repeat 0 center;font-size:15px;font-family: 'Satoshi-Medium';display: block;float: right;background-size: 20px;}
.hide-d{display:none!important;}
input,select,textarea{font-size: 14px;}
@media only screen and (min-width:1100px){
.w-1{width:4%;}	
.w-12{width:12%;}	
.w-2{width: 100%;padding: 0;}	 
.w-3{width:17% !important;}	
.upload-steps ul{margin-left:0;}
.upload-steps.upload-steps3 ul{margin-left:0;}
.upload-steps.upload-steps4 ul{margin-left:4%;}
.upload-steps.mr-l-2 ul{margin-left: 70px;}
.stack-card {display: flex;justify-content: center;align-items: center;align-content: center;height: 100vh;position: sticky;align-content:center;top: 0;}
.edit-design.tabbing-section .save-link{margin-left: 2%;}
}
@media only screen and (min-width:768px) and (max-width:991px){
.upload-review .back-arrow{width: 10%;}
.upload-review .design-review{width:70%;padding: 0;}
.upload-review .next-cncl{width: 15%;}

}
@media only screen and (max-width:991px){
.upload-steps ul{margin-left: 0;}
.upload-btn-wrapper .btn{padding: 100px 30px 50px 30px;}
}
@media only screen and (max-width:767px){
.upload-steps ul li{padding: 0 15px;font-size: 14px;}
.upload-steps ul li::after{background-size: 13px !important;}

.upload-btn-wrapper{margin:0;}
.edit-design {text-align: left;margin-top: 20px;}
.browse-bt {margin-top: 20px;}
.next-cncl{width:auto;display: block;margin-top: 20px;height: auto;}
.txt4{margin-top: 20px;line-height: 1.3;}
.design-review {width: 100%;padding-left: 0;padding-right: 0;}
.design-review-row1{margin-top: 25px;}
.continue-btns{margin-top:15px;}
.continue-btns a{padding: 8px 10px 10px;letter-spacing: 1px;font-size: 14px;}
.design-review-col1{text-align: center;}
.review-img{margin-bottom:10px;}
.review .upload-container1{margin-top:20px;}
.frnt-bck{display: inline-block;margin-left: 20px;}
.hide-d{display:block!important;}
.hide-m{display:none!important;}
.review.tabbing .upload-steps, .review .upload-steps {margin-top:-90px;}
}

.tabbing-section{width: 75%;}
.tabbing .upload-container1{position:relative;}
.tabbing-section .tab{position: absolute;left: 0;top: 30px; max-width: 200px;z-index: 9;}
.tabbing-section .tabcontent{position: absolute;left: 0;top: 140px;width: 100%;text-align: left;border: 0;padding: 0;}
.design-tool-list{padding:0;}
.design-tools{float:left;/* width:30%; */width:20%;}
.design-canvas{float:left;width:100%;margin-top:5px;text-align: center;}
.design-tool-list li{color: #1B5ADE;padding:0 0 10px;font-family: 'Satoshi-Medium';}
.design-tool-list img{max-width: 20px;margin-right: 10px;vertical-align: middle;}
.tabbing-section.edit-design a.edit-a{padding-left: 140px;}
.zoom-canvas{text-align:center;color:#7E7E7E;margin-top: 25px;font-family: 'Satoshi-Bold';}
.tab {overflow: hidden; border:0px solid #ccc; }
.tabcontent {padding: 6px 12px; border:0px solid #ccc; border-top: none;}  
.tab button {background-color: inherit; float: left; border: none;outline: none; cursor: pointer; padding:2px 20px 4px;  transition: 0.3s;text-transform:uppercase;border-right: 1px solid #9A9A9A;color: #9A9A9A;font-family: 'Satoshi-Medium';}
.tab button:last-child{border:0;}    
.tab button:hover {color: #1B5ADE;}
.tab .active {color: #1B5ADE;font-family: 'Satoshi-Bold';}
.tabcontent {padding:0;
border:0px solid #ccc; border-top: none;}
.design-canvas .dv-tb{position:relative;display: inline-block;}
.dv-tp{float: left;width: 100%;height: 50%;background:transparent;position: absolute;}
.dv-bt{float: left;width: 100%;height: 50%;background:transparent;position: absolute;top:50%;}
.tooltip-3,.tooltip-4{display:none;}
.safe-area{width: 68%;position: absolute;left: -283px;top: 35px;border: 1px solid #1B5ADE;padding: 10px;background: #fff;font-size: 12px;line-height: 1.2;color: #9A9A9A;}
.safe-area p{margin:0;}
.tooltip-hd{color:#1B5ADE;font-size:15px;margin-bottom: 5px;font-family: 'Satoshi-Medium';}
.design-canvas .dv-tp:hover .tooltip-3{display:block;}
.design-canvas .dv-bt:hover .tooltip-4{display:block;}
.tooltip-3.safe-area{top: 35px;left: -300px;}
.safe-area img{float: left;max-width: 50px;margin: 0 10px 40px 0;}
.safe-area::before {content: '';width: 0;height: 0;border-top: 7px solid transparent;border-left: 7px solid #1b5ade;border-bottom: 7px solid transparent;position: absolute;top: 40%;right: -8px;}
.safe-area::after {content: '';width: 0;height: 0;border-top: 7px solid transparent;border-left: 8px solid #fff;border-bottom: 7px solid transparent;position: absolute;top: 40%;right: -7px;}
@media only screen and (min-width:1000px){
.tabbing-section {width: 100%;margin-top: 0;}
.next-cncl{top: 0;right: 0;position: absolute;}
.top-panel .container {max-width: 92%;}
#canvas-here {margin-top: 35px;position:absolute;}
.design-frnt-bck.frnt-bck{position: absolute;}
.back-arrow.back-arrow-align {position: absolute;}
.design-canvas-new {height:65vh !important;}
}
@media only screen and (min-width:1800px){
.next-cncl{right: -110px;}

}
@media only screen and (min-width:1001px) and (max-width:1100px){
.review.tabbing .upload-steps {margin-top: -105px;}
.review .upload-container1 {margin-top: 3%;}
.upload-steps.upload-steps3 ul{margin-left: 9%;}

}
@media only screen and (min-width:768px) and (max-width:1000px){
.tabbing-section {width:100%;margin-top: 30px;}
.tabbing-section .tabcontent{width: 70%;}
.design-tools{width:40%;}
.design-canvas{width:100%;}
.safe-area{font-size: 11px;left: -205px;line-height: 1;top: 0;}
.tooltip-3.safe-area{left: -215px;top: 0;}
.review.tabbing .upload-steps {margin-top: -65px;}
.upload-steps.upload-steps3 ul{margin-left:0;}
.next-cncl{right: 0;top:7px;position: absolute;}
.design-frnt-bck.frnt-bck{margin-top:-50px !important;}
.design-container .pollSlider{right: 20px;}
.design-container #pollSlider-button{right: 2.8%;}
}
@media only screen and (max-width:767px){
.tabbing-section {width: 100%;}
.tabbing-section.edit-design a.edit-a{padding-left: 0;}
.tabbing-section .tabcontent{width: 100%;position:static;}
.design-tools{width:100%;}
.design-canvas{width: 100%;margin: 15px 0 10px;}
.tabbing-section .tab{position:static;margin:15px 0 10px;}
.tabbing-in .design-container{padding:0;}
.design-tool-list li{float: left;width: 50%;}
.next-cncl{margin-bottom: 20px;}
.design-tool-list img{margin-right: 5px;}
.safe-area{display:none;}
.review.tabbing .upload-steps {margin-top:-77px;}
}

/*------------------------------------
COMMON CSS
-------------------------------------*/
@media only screen and (min-width:1000px){
.top-panel .design-container{max-width: 92%;}

}



@media only screen and (min-width:1800px){
.upload-steps ul li:after{width: 25px;height: 25px;top:10px;right: -15px;background-size: 25px;position: absolute;}
.back-arrow img{width:35px;}
.next-cncl{width: 300px;}
.design-canvas{width: 40%;}
.design-canvas .dv-tb{width: 100%;}
.design-canvas img{width: 100%;}
.tabbing .upload-container1{height: 75vh;}
.tabbing-section .tabcontent{top: 270px;}
.tabbing-section .tab{top:100px;}
.tabbing-section.edit-design a.edit-a {padding-left: 20%;}
.txt4{margin-top: 450px;}
.safe-area{left: -445px;}
.tooltip-3.safe-area{left: -470px;}
.safe-area img{max-width: 120px;margin: 0 10px 10px 0;}
.design-tool-list img {max-width: 30px;}
.review .upload-container1 {margin-top: 110px;}

}

@media only screen and (min-width:3000px){
.upload-steps ul li:after{width: 60px;height: 60px;top: 22px;right: -40px;background-size: 50px;position: absolute;}
.upload-steps ul li{padding: 0 70px;}
.back-arrow img{width: 75px;}
.edit-design img.edit-icon{max-width:40px;}
.upload-container1{margin-top: 130px;}
.tabbing-section.edit-design a.edit-a {padding-left:18%;}
.tabbing .upload-container1 .next-cncl { width: 16%;}
.safe-area img{max-width: 260px;margin-right:40px;}
.safe-area{padding:30px;}
.tooltip-3.safe-area { left: -68%;}
.safe-area {left: -65%;}

}

/*------------------------------------
COMMON CSS
-------------------------------------*/
@font-face {
  font-family: 'Satoshi-Variable';
  src: url('../../public/fonts/Satoshi-Variable.woff2') format('woff2'),
       url('../../public/fonts/Satoshi-Variable.woff') format('woff'),
       url('../../public/fonts/Satoshi-Variable.ttf') format('truetype');
       font-weight: 300 900;
       font-display: swap;
       font-style: normal;
}


/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 900.0;
*
* available axes:

* 'wght' (range from 300.0 to 900.0)

*/

@font-face {
  font-family: 'Satoshi-VariableItalic';
  src: url('../../public/fonts/Satoshi-VariableItalic.woff2') format('woff2'),
       url('../../public/fonts/Satoshi-VariableItalic.woff') format('woff'),
       url('../../public/fonts/Satoshi-VariableItalic.ttf') format('truetype');
       font-weight: 300 900;
       font-display: swap;
       font-style: italic;
}


@font-face {
  font-family: 'Satoshi-Light';
  src: url('../../public/fonts/Satoshi-Light.woff2') format('woff2'),
       url('../../public/fonts/Satoshi-Light.woff') format('woff'),
       url('../../public/fonts/Satoshi-Light.ttf') format('truetype');
       font-weight: 300;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'Satoshi-LightItalic';
  src: url('../../public/fonts/Satoshi-LightItalic.woff2') format('woff2'),
       url('../../public/fonts/Satoshi-LightItalic.woff') format('woff'),
       url('../../public/fonts/Satoshi-LightItalic.ttf') format('truetype');
       font-weight: 300;
       font-display: swap;
       font-style: italic;
}


@font-face {
  font-family: 'Satoshi-Regular';
  src: url('../../public/fonts/Satoshi-Regular.woff2') format('woff2'),
       url('../../public/fonts/Satoshi-Regular.woff') format('woff'),
       url('../../public/fonts/Satoshi-Regular.ttf') format('truetype');
       font-weight: 400;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'Satoshi-Italic';
  src: url('../../public/fonts/Satoshi-Italic.woff2') format('woff2'),
       url('../../public/fonts/Satoshi-Italic.woff') format('woff'),
       url('../../public/fonts/Satoshi-Italic.ttf') format('truetype');
       font-weight: 400;
       font-display: swap;
       font-style: italic;
}


@font-face {
  font-family: 'Satoshi-Medium';
  src: url('../../public/fonts/Satoshi-Medium.woff2') format('woff2'),
       url('../../public/fonts/Satoshi-Medium.woff') format('woff'),
       url('../../public/fonts/Satoshi-Medium.ttf') format('truetype');
       font-weight: 500;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'Satoshi-MediumItalic';
  src: url('../../public/fonts/Satoshi-MediumItalic.woff2') format('woff2'),
       url('../../public/fonts/Satoshi-MediumItalic.woff') format('woff'),
       url('../../public/fonts/Satoshi-MediumItalic.ttf') format('truetype');
       font-weight: 500;
       font-display: swap;
       font-style: italic;
}


@font-face {
  font-family: 'Satoshi-Bold';
  src: url('../../public/fonts/Satoshi-Bold.woff2') format('woff2'),
       url('../../public/fonts/Satoshi-Bold.woff') format('woff'),
       url('../../public/fonts/Satoshi-Bold.ttf') format('truetype');
       font-weight: 700;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'Satoshi-BoldItalic';
  src: url('../../public/fonts/Satoshi-BoldItalic.woff2') format('woff2'),
       url('../../public/fonts/Satoshi-BoldItalic.woff') format('woff'),
       url('../../public/fonts/Satoshi-BoldItalic.ttf') format('truetype');
       font-weight: 700;
       font-display: swap;
       font-style: italic;
}


@font-face {
  font-family: 'Satoshi-Black';
  src: url('../../public/fonts/Satoshi-Black.woff2') format('woff2'),
       url('../../public/fonts/Satoshi-Black.woff') format('woff'),
       url('../../public/fonts/Satoshi-Black.ttf') format('truetype');
       font-weight: 900;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'Satoshi-BlackItalic';
  src: url('../../public/fonts/Satoshi-BlackItalic.woff2') format('woff2'),
       url('../../public/fonts/Satoshi-BlackItalic.woff') format('woff'),
       url('../../public/fonts/Satoshi-BlackItalic.ttf') format('truetype');
       font-weight: 900;
       font-display: swap;
       font-style: italic;
}








:root {

/* Colors: */
--unnamed-color-273b35: #273B35;
--unnamed-color-000000: #000000;
--unnamed-color-747474: #747474;

/* Font/text values */
--unnamed-font-family-satoshi: Satoshi;
--unnamed-font-style-normal: normal;
--unnamed-font-weight-medium: medium;
--unnamed-font-weight-normal: normal;
--unnamed-font-size-18: 18px;
--unnamed-font-size-20: 20px;
--unnamed-character-spacing-0: 0px;
--unnamed-line-spacing-28: 28px;
--unnamed-line-spacing-36: 36px;
--unnamed-line-spacing-46: 46px;
}

/* Character Styles */
/* .stack__card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: sticky;
  top: 0;
  box-shadow: 0 -1px 3px rgba(0,0,0,0.1), 0 -1px 2px rgba(0,0,0,0.25);
  font-size: 10rem;
} */

/* Hide the browser's default checkbox */

/* Create a custom checkbox */

/* On mouse-over, add a grey background color */
/* .box3:hover input ~ .checkmark {
  background-color: #1B5ADE;
} */

/* When the checkbox is checked, add a blue background */

/* Create the checkmark/indicator (hidden when not checked) */

/* Show the checkmark when checked */

/* Style the checkmark/indicator */


/*-- RDG --*/
.editor-canvas {width:670px;}
.dropdown { position: relative; display: inline-block;float: left;
width: 100%;}
.dropbtn { cursor: pointer; }
.dropdown-content { display: none; position: absolute; background-color: #fff; width: 315px; overflow: auto; box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); z-index: 1; left: 0; top:-295px; }
/* #myDropdownshape.dropdown-content { width: 200px; } */
.show {display: block;}
.btns-left { width: auto; float: left; position: absolute; left: 50px; top: 2px; }
.btns-left a { color: #9A9A9A; padding: 7px 15px; line-height: 1; border: 1px solid #9A9A9A; font-family: 'Satoshi-Medium'; font-size: 14px; transition:0.5s; float: left; margin-right: 5px; }
.btns-left a:last-child {margin-right:0;}
.btns-left a:hover { color: #1B5ADE; border: 1px solid #1B5ADE; }
.add-field { float: left; width: 100%; position: relative; text-align:center; border: 2px dashed rgba(0,0,0,.2);background: #fff;padding: 20px;margin: 15px 0;}
.add-field input[type="file"]{ width: 100%; height: 100%; position: absolute; left: 0; top: 0; opacity:0; }
.add-field .button-part { width: 100%; float: left; }
.add-field .button-part button { border: 1px solid #1B5ADE; color: #1B5ADE; padding: 10px 15px; line-height: 1; background: #fff; }
.add-img-popup .modal-dialog { max-width: 700px; }
.add-img-popup .modal-footer { border:0; }
.add-field div { margin-top: 10px; float: left; width: 100%; line-height: 1.3; }
.add-field .red-span { color:red }
.cmyk-prt { font-size: 9px; line-height: 1.2; margin-bottom: 10px; margin-top: 2px; color: #000; }
.cmyk-w { width: 15px; display:inline-block !important; }
.menu-icon {
  width: 30px;
  display: inline-block;
}
.menu-txtt, .menu-textt {
  /*! width: 30px; */
  display: inline-block;
}
#myDropdownshape {
	width: 200px;
}
#menuBarDiv{ border-radius:3px 3px 0 0; border-bottom:none; min-height:25px; padding:5px 0; width: 100%; max-width:100%; position: absolute;top: 0;left: 0;
}
#menuBarDiv .dropdown{float:left;}
#menuBarDiv .dropdown-toggle{font-size:14px; }
#menuBarDiv .dropdown-toggle .caret{margin-left:5px; border-top-color:#999;}
#menuBarDiv a.btn{position:relative; margin-bottom:3px; box-shadow: none; padding: 3px 10px; font-size: 14px; border-radius: 0; border: 1px solid #aaa !important; background: #fff; display:block}
#menuBarDiv a.btn:focus { color: #000; border: 0; box-shadow: none; border-radius: 0; background: #aaa; }
#menuBarDiv .dropdown-menu { padding: 5px 10px; }
#menuBarDiv .dropdown-menu li { padding: 7px 0px; line-height: 1; border-bottom: 1px solid #e8e8e8; float: left; width: 100%; }
#menuBarDiv .dropdown-menu li:last-child {border-bottom:0;}
#menuBarDiv .dropdown-menu li a { font-size: 14px; }
.scroll-fixed { background: #F0F0F0 ; color: #000; position: fixed; padding:1.5% 2% 1.5%; bottom:24%;left: 6%; border-radius:0; font-size: 12px; width: 270px; /* height: 80px;  */font-weight: 400; text-align: center; display: flex; align-items: center; line-height: 1.3; flex-wrap: wrap; align-content: center;z-index: 9;}
.scroll-fixed p {margin-bottom: 0;display: block;width: 100%;text-align: left;font-size: 14px;}
.scroll-fixed p:last-child {margin-bottom:0;}
.next-cncl .design-tools { width: 100%; margin-top: 50px; }
.next-cncl .txt4 { margin-top: 50px; }
.color-palette.show li{height: 195px;overflow-y: scroll;scrollbar-width: thin;scrollbar-color: #ddd #fff;}
.color-palette.show li {
scrollbar-width:thin;scrollbar-color:#ddd #fff;height:195px;overflow-y:scroll;width:100%;scrollbar-color:#ddd #fff
}
.color-palette.show li::-webkit-scrollbar{height:5px;width:5px;background:#fff}
.color-palette.show li::-webkit-scrollbar-thumb{background:#ddd;-webkit-border-radius:1ex}


.clr-prt { width: 21px; height: 15px; float: left; background: #b71c1c; margin: 2px; }
.clr-prt:nth-child(2) { background: #880e4f; }
.clr-prt:nth-child(3) { background: #4a148c; }
.clr-prt:nth-child(4) { background: #d32f2f; }
.clr-prt:nth-child(5) { background: #c2185b; }
.clr-prt:nth-child(6) { background: #7b1fa2; }
.clr-prt:nth-child(7) { background: #f44336; }
.clr-prt:nth-child(8) { background: #e91e63; }
.clr-prt:nth-child(9) { background: #9c27b0; }
.clr-prt:nth-child(10) { background: #e57373; }
.clr-prt:nth-child(11) { background: #f06292; }
.clr-prt:nth-child(12) { background: #ba68c8; }
.clr-prt:nth-child(13) { background: #ba68B8; }
.clr-prt:nth-child(14) { background: #ba68A8; }
.clr-prt:nth-child(15) { background: #ba68D8; }


.modal-footer .btn {color: #fff;
    background-color: #0069d9;
    border-color: #0069d9;
    border: 1px solid transparent;
    padding: 0.4375rem 1.125rem;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.25rem;
    border-radius: 4px;
    min-width: 100px;
    text-align: center;
    display: inline-block;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    text-decoration: none;
    background-size: 25px !important;
    font-family: 'Kumbh Sans', sans-serif; }
.shape-menu-list {
  float: left;
  width: 100%;
}
.shape-list {
  float: left;
  width: 100%;
  padding: 3px 10px;
}
.shape-svg {
  float: left;
  width: 30px;
  text-align: center; margin-right: 5px;
}
.shape-svg img {
  margin-right: 0;
}
.shape-ttxt {
	font-size: 14px;
	color: #a6a6a6;
}


@media only screen and (min-width:1600px){
.scroll-fixed {bottom:30%}
}

@media only screen and (max-width:767px){
.scroll-fixed {display:none}
}

.pollSlider{
    position:fixed;
     width:100%;
    background: #FFFFFF;
box-shadow: 0px -12px 46px #00000029;
   bottom :-132px;
    height:132px;max-width:87.7%;max-width: 87.7%;
z-index: 99;right: 83px;
    
}
#pollSlider-button{
    position:fixed;
    width:30px;
    height:30px;
    bottom:0px;right:6.15%;cursor:pointer;
    background:url(../../public/images/arrow1.png) no-repeat 0 0 !important;   
    /* arrow1-up.png */
}
#pollSlider-button.active{background:url(../../public/images/arrow1-up.png) no-repeat 0 0 !important;}
.editor-options-wrap {display: flex;padding:0 2%;justify-content: space-around;display: -webkit-flex;height: 105px;}
.editor-options-img {display: flex;flex-wrap: wrap;font-size: 12px;justify-content: center;text-align: center;width: 100%;}
.add-clm1 span{display:block;padding:2px 0}
.editor-options-clm1 {min-width:12%;border-right: 1px solid #b0b0b0;display: flex;
flex-wrap: wrap;padding: 1% 0;}
.editor-options-clm1:last-child{border:0;}
.add-clm1 {padding:0 1%;}
.editor-options-txt {text-align: center;letter-spacing: 1.8px;color: #1B5ADE;font-family: 'Satoshi-medium';width: 100%;text-transform: uppercase;}
.add-clm1 img{max-height: 25px;}
.add-clm1 img.circle-img{max-height: 33px;}
.edit-a.purple-link{letter-spacing: -0.22px;color: #6F1971;}
.edit-a.purple-link span{border-bottom: 1px dotted #6F1971;}
.hint-txt1 {font-size: 11px;margin-bottom: 5% !important;}
.blue-txt1{letter-spacing: -0.2px;color: #1B5ADE;font-family: 'Satoshi-medium';}
.close-btn {position: absolute;right: 10px;top: 10px;}
.fa-align-delete img{max-height: 12px;}
.block-div {display: block;font-size: 9px;color: #FF5661;}
.delete-icn{line-height: 1;padding: 0 5px;}
.delete-icn {padding-top: 0 !important;padding-bottom: 0 !important;}
.object-element-wrap {position: absolute;
   /* bottom:30%; */
    width: auto; display: flex; background: #F4F4F4; box-shadow: 0px 3px 17px #00000066; border: 1px solid #000000; padding:7px 10px 3px 0; font-size: 10px;
    /* right: 15%; */
    z-index: 99;
  }
.object-element-clm {width: 25%;text-align: center;display: flex;flex-wrap: wrap;justify-content: center;}
.element-img img {max-height: 22px;}
.border-element {background: #FF943A;width: 22px;height: 22px;}
.fill-element {background: #6F1971;width: 22px;height: 22px;}
.element-hd {width: 100%;font-family: 'Satoshi-medium';line-height: 1;white-space: nowrap;margin-top: 3px;margin-bottom: 3px;}
.delete-element .element-hd{color: #FF5661;}
.save-link {margin-left: 4%;letter-spacing: 1.93px;color: #1B5ADE !important;text-transform:uppercase;}
.save-link img{max-width: 18px !important;margin-top: -5px;}
.frnt-bck a.active {
  color:#1b5ade !important;
  font-family: 'Satoshi-Bold';
}
.frnt-bck a {
  color: #7E7E7E;
  padding: 0 15px;
  position: relative;
  font-family: 'Satoshi-Medium';
}
.frnt-bck a::after {
  content: '';
  position: absolute;
  border-right: 1px solid #8f8f8f;
  height: 100%;
  top: 0;
  right: 0;
}
.frnt-bck a:last-child::after{content:unset;}
.next-cncl a.cncl-btn-color:hover{color: #FF5661;border-color:#FF5661;}
.tool-shadow{box-shadow: 0px 3px 17px #00000066;border: 1px solid #000000;display: inline-block;position: relative;z-index: 9;}
#menuBarDiv .tool-shadow a.btn{margin-bottom:0;border-top: 0 !important;border-bottom: 0 !important;}
.tool-shadow .btn-group{vertical-align: top;}







@media only screen and (min-width:768px){
.frnt-bck{margin-top: 50px;position:relative;z-index: 9;margin-left: -66px;}
.editor-options-clm1.editor-clm-1{width:14%;}
.editor-options-clm1.editor-clm-1 .add-clm1{width:33.33%;}
.editor-options-clm1.editor-clm-2{width:16%;}
.editor-options-clm1.editor-clm-3{width:33%;}
.editor-options-clm1.editor-clm-4{width:18%;}
.editor-options-clm1.editor-clm-5{width:35%;}
.editor-options-clm1.editor-clm-3 .add-clm1{width:25%;}
.editor-options-clm1.editor-clm-4 .add-clm1{width:50%;}
.editor-options-clm1.editor-clm-5 .add-clm1{width:20%;}
.admin-editor .editor-options-clm1.editor-clm-1 .add-clm1{width:33%;}
.admin-editor .editor-options-clm1.editor-clm-1 {width:32%;}
.admin-editor .editor-options-clm1.editor-clm-2 {width:36%;}
.admin-editor .editor-options-clm1.editor-clm-2 .add-clm1{width:25%;}
.admin-editor .editor-options-clm1.editor-clm-3 .add-clm1 {width: 33.33%;}
.admin-editor .editor-options-clm1.editor-clm-3 {width:23%;}
.editor-options-clm1.editor-clm-1.editor-save-btn {width: 9%;min-width: unset;}
.admin-editor .editor-options-clm1.editor-clm-1.three-box-editor .add-clm1{width:33.33%;}
.admin-editor .editor-options-clm1.editor-clm-1.three-box-editor .add-clm1 span{line-height:1.1}



}
@media only screen and (min-width:1100px){
.frnt-bck a {font-size: 1.10vw;}
}
@media only screen and (min-width:768px) and (max-width:1100px){
.pollSlider{max-width: 95.7%;}
.add-clm1 span{line-height: 1.2;}
.editor-options-img{min-height: 100px;}
.add-clm1 {padding: 0 0%;width: 50%;font-size: 11px;}
.editor-options-clm1{flex-wrap: wrap;padding: 1% 0;min-width: 19%;}
.pollSlider{bottom: -139px;height: 139px;}
}

@media only screen and (min-width:1000px) and (max-width:1100px){
.pollSlider{max-width: 89.5%;}

}
@media only screen and (max-width:767px){
.editor-options-clm1 {min-width:100%;margin-top:1%;margin-bottom:1%;border-bottom: 1px solid #b0b0b0;}
.editor-options-clm1:nth-child(2n),.editor-options-clm1:nth-child(3n){border-right:0;}
.editor-options-clm1:nth-child(4){width:100%;}
.editor-options-clm1:last-child{border:0;}
.editor-options-wrap{flex-wrap: wrap;}
.editor-options-img{font-size: 11px;}
.pollSlider{bottom: -390px;height: 390px;}
.pollSlider{max-width: 93%;right: 10px;}
.add-clm1 {padding: 0 2%;}
.edit-design a{font-size: 15px;}
.frnt-bck{margin-left: 0;margin-right: 45px;margin-top: 15px;}
.editor-options-clm1.editor-clm-1{border-right:0;}
#pollSlider-button{right:10px;}
}
@media only screen and (min-width:1600px){
.object-element-wrap{right:25%;}
.pollSlider{max-width: 88.7%;}
.design-canvas-new{margin:-80px auto 0;float: none;}
.save-link img {max-width: 25px !important;margin-top: -6px;}
.next-cncl a.active, .next-cncl a:hover,.next-cncl a{font-size: 0.95vw;letter-spacing: 1.93px;}
.edit-design a {font-size: 1vw;}
.scroll-fixed{width:19%;padding:2% 2%;right:4%;}
.scroll-fixed .hint-txt1{font-size: 0.95vw;}
.scroll-fixed p{font-size:1vw;}
.editor-options-txt{font-size:1vw;margin-top: 2%;}
.add-clm1 span{font-size:0.65vw;}
.add-clm1 {padding: 0 2%;}
#menuBarDiv a.btn{font-size: 20px;}
.fa-align-delete img {max-height: 16px;}
.block-div{font-size: 13px;}
.object-element-wrap{font-size:13px;}
.frnt-bck{margin-left: -7%;margin-top: 5%;}

.editor-options-clm1.editor-clm-1{width:17%;}
.editor-options-clm1.editor-clm-1 .add-clm1{width:25%;}
.editor-options-clm1.editor-clm-2{width:16%;}
.editor-options-clm1.editor-clm-3{width:31%;}
.editor-options-clm1.editor-clm-4{width:18%;}
.editor-options-clm1.editor-clm-5{width:34%;}
.editor-options-clm1.editor-clm-3 .add-clm1{width:20%;}
.editor-options-clm1.editor-clm-4 .add-clm1{width:38%;}
.editor-options-clm1.editor-clm-5 .add-clm1{width:20%;}
#pollSlider-button{right: 5.5%;}
.next-cncl a.active, .next-cncl a:hover{border: 2px solid #1B5ADE;}
.upload-steps ul li.active a{border-bottom: 2px solid #1B5ADE;}
.next-cncl a.cncl-btn-color{border-width:2px;}

}
@media only screen and (min-width:2000px){
.next-cncl a.active, .next-cncl a:hover,.next-cncl a{font-size:0.65vw;}
.save-link img {margin-top:-11px;width: 29px !important;max-width: unset !important;}
.upload-steps ul li {font-size: 1vw;}
.upload-steps ul li::after{background-size: 38px;top: 10px;}
.scroll-fixed{width: 10%;padding: 1.5% 1%;right:0.5%;}
.scroll-fixed .hint-txt1{font-size: 0.6vw;}
.scroll-fixed p{font-size:0.45vw;}
.pollSlider{height: 200px;bottom:-200px;}
.pollSlider {max-width: 89.9%;}
.add-clm1 {padding: 0 3%;}
.editor-options-clm1{width: 25%;}
.edit-design a {font-size: 0.65vw;}
.frnt-bck{padding-top:0;}
#pollSlider-button {right: 4.7%;}
}
@media only screen and (min-width:3000px){
#pollSlider-button {right: 4.7%;}

}

/*----3D View-----*/

.drawer-wrapper{
  margin:0;
  display: inline-block;float: right;position: relative;
}

/*The left drawer*/ 
#drawer-left{/*set a container with the total width of both the container and the trigger*/
  position: relative;
  height: 100%; width:500px;
  overflow: hidden;
  transition: all .35s ease-in-out;
}
#drawer-left:after{/*this will the right border, when the content will be pushed out*/
  content: '';
  position: absolute;
  right: 0; top: 0;  bottom: 0;
  border-right: 0px solid #000;
}
.drawer-left-trigger{
  /*set the triggers width here, borders etc.*/
  position: absolute;
  top: 45%; bottom: 0; right: 100%;
  margin-right: -38px;/*bring it back inside the wrapper*/
  width:48px;  
  background:url(../../public/images/slide-arrow-close.png) no-repeat right center!important;
  /*some styling stuff*/
  cursor: pointer;
  text-align: center;
  font-size: 24px;
  line-height: 100%;
}
.opened .drawer-left-trigger{margin-right: -48px;}
.closed .drawer-left-trigger{background:url(../../public/images/slide-arrow-open.png) no-repeat right center!important;}
.drawer-left-trigger > span{
  transform: rotate(180deg);
  transition: all .35s ease-in-out;  
}
#drawer-left.closed .drawer-left-trigger > span{
  transform: rotate(0);
}
#drawer-left.closed .drawer-left-trigger{
  /*this will push the trigger on the right side*/
  left: auto;
  right: 46px;top: 45%;
}
.drawer-left-content{
  /*this is the container for the header and content*/
  position: absolute;background: #FFFFFF 0% 0% no-repeat padding-box;
/* box-shadow: 0px 3px 56px #00000029; */z-index: 999;
  top: 0; bottom: 0; right: 0; left: 46px;/*the triggers width(+-1px from the border)*/
}
#drawer-left.closed .drawer-left-content{
  /*this will push the content out*/
  left: 98.2%;
  right: -98.2%;
}
.drawer-left-trigger,
.drawer-left-content{
  border-left: 0px solid #000;
  border-bottom: 0px solid #000;
  border-top: 0px solid #000;
  transition: all .35s ease-in-out;background: transparent linear-gradient(180deg, #E6E6E6 0%, #C6C6C6 100%) 0% 0% no-repeat padding-box;
}
/* .drawer-left-content img{border:10px solid #fff;} */
.drawer-left-content{border:10px solid #fff;object-fit: cover;display:flex;}
/* background:url(../../public/images/icon-3d-view.svg) no-repeat 0 center */
.hd-3d-view {letter-spacing: -0.23px;color: #1b5ade;font-size: 1.3vw;font-family: 'Satoshi-Medium';padding-left: 40px;position: absolute;right:16%;top: 45.5%;}
#drawer-left.opened{z-index:99;}
.drawer-left-content img{width:100%;object-fit: cover;}

@media only screen and (max-width:767px){
.hd-3d-view{font-size: 15px;}

}
@media only screen and (min-width:1000px){
.review1 .continue-btns{width: 100%;margin-top: 60px;}
.review1 .design-review.edit-design.w-2 .continue-btns{justify-content: right;}
}
@media only screen and (min-width:1100px) and (max-width:1400px){
.drawer-wrapper{margin: 0;}
.pollSlider{bottom: -105px;height: 105px;}
}

@media only screen and (min-width:1800px){
.drawer-wrapper{margin:-40% 0 0 0;}
.hd-3d-view{top:44%;}
.design-frnt-bck.frnt-bck{height: 47px;}
.design-canvas.design-canvas-new{margin-top:-7%}
.add-clm1 img {max-height: unset;}
.add-clm1 span:first-child{min-height:35px;}
.frnt-bck{padding: 15px 0;}
.next-cncl a{padding:13px 20px;}
}
@media only screen and (min-width:2000px){
.drawer-wrapper{margin:-25% 0 0 0;}
.hd-3d-view{top:38.5%;}
.design-frnt-bck.frnt-bck{height: 47px;}
.design-canvas.design-canvas-new{margin-top:0}
}

.design-container {
  max-width: 90%;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
a{
  cursor: pointer;
}

.product-slider { padding: 45px; }
.product-slider #carousel { border: 4px solid #1089c0; margin: 0; }
.product-slider #thumbcarousel { margin: 12px 0 0; padding: 0 45px; }
.product-slider #thumbcarousel .item { text-align: center; }
.product-slider #thumbcarousel .item .thumb { border: 4px solid #cecece; width: 20%; margin: 0 2%; display: inline-block; vertical-align: middle; cursor: pointer; max-width: 98px; }
.product-slider #thumbcarousel .item .thumb:hover { border-color: #1089c0; }
.product-slider .item img { width: 100%; height: auto; }
.carousel-control { color: #0284b8; text-align: center; text-shadow: none; font-size: 30px; width: 30px; height: 30px; line-height: 20px; top: 23%; }
.carousel-control:hover, .carousel-control:focus, .carousel-control:active { color: #333; }
.carousel-caption, .carousel-control .fa { font: normal normal normal 30px/26px FontAwesome; }
.carousel-control { background-color: rgba(0, 0, 0, 0); bottom: auto; font-size: 20px; left: 0; position: absolute; top: 30%; width: auto; }
.carousel-control.right, .carousel-control.left { background-color: rgba(0, 0, 0, 0); background-image: none; }
.design-carousel {float: left; width: 100%; text-align: center; }
.design-carousel .design-container{margin:0 auto;max-width: 549px;}
.design-carousel .carousel-indicators li{width: 80px;}
.design-carousel .carousel-indicators li img{display: inherit;}
.design-carousel .carousel-indicators{bottom: -18px;}
.design-frnt-bck.frnt-bck {margin: 0 auto 15px;}
.mrgt-75{margin-top: 75px;}
.carousel-item span{display: block;margin: 10px 0;}
.design-carousel .carousel-inner img {max-width: 440px;}
.position-r{position:relative;}
.position-a{position:absolute;top: 45px;width: auto;display: flex;}
@media only screen and (min-width:1100px) and (max-width:1500px){
.review1 .design-review-row1{margin-top: 5%;}
.review .upload-container1 {margin-top:60px;}
.review .upload-container1.mrtp-21 {margin-top:2%;}
}
@media only screen and (min-width:1800px){
.design-frnt-bck.frnt-bck{height: auto;}
.position-a{width: auto;}
}
@media only screen and (min-width:2000px){
.design-frnt-bck.frnt-bck{height: auto;}
.position-a{width: auto;top: 110px;}
}


/*------------------------------------
BLANK DESIGN1
-------------------------------------*/
.blank-design1 .create-steps{margin-top:55px;} 
.blank-design1 .upload-steps{padding: 0 100px;} 
.start-design-panel{text-align:center;margin-top:95px;}
.design-input{font-size: 18px;text-align: center;border:0;border-bottom:1px solid #1B5ADE;padding:0 0 5px 0;border-radius:0;/* background:url(../../public/images/edit-blue.svg) no-repeat right center; */background-size: 20px;margin-right: 0;width:16%;background:transparent;font-family: 'Satoshi-Medium';color: #000;}
::placeholder {color: #676767;}
:-ms-input-placeholder {color: #676767;}
::-ms-input-placeholder { color: #676767;}
input:focus::placeholder,textarea:focus::placeholder {color: transparent;}
input:focus::-ms-input-placeholder,textarea:focus::-ms-input-placeholder{color: transparent;}
input:focus::-webkit-input-placeholder,textarea:focus::-webkit-input-placeholder { color:transparent; }



.edit-design.tabbing-section a span{border-bottom: 1px solid #1B5ADE;display: inline-block;width: 16%;padding: 0 0 6px 0;}
.start-design1{justify-content: center;}
.submit-btn1{color: #1B5ADE;border:2px solid #1B5ADE;letter-spacing: 1.93px;font-size: 14px;padding:12px 35px 12px;line-height: 1;border-radius:0;background: transparent;-webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out;  -ms-transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out;margin-top:0;margin-bottom:70px;}
.submit-btn1:hover{color: #fff;background: #1B5ADE;}
.need-help a{color: #1B5ADE;font-size: 15px;}
.need-help a:hover{text-decoration:underline;}
.label-info{color: #1B5ADE;border: 1px solid #1B5ADE;background:#fff;line-height: 1;height: auto;padding: 5px 15px;position:relative;}
.label-info:after{content:''; width: 0;height: 0;border-top:7px solid transparent;border-right: 8px solid #fff;border-bottom: 7px solid transparent;position:absolute;top:8px;left:-7px;}
.label-info:before{content:''; width: 0;height: 0;border-top:7px solid transparent;border-right: 7px solid #1b5ade;border-bottom: 7px solid transparent;position:absolute;top:8px;left:-8px;}
.upload-steps ul li.completed::after{background: url(../../public/images/right-arrow2.svg) no-repeat 0 center;}
.upload-steps ul li.completed a{border-bottom:0;}
.upload-steps.upload1{padding-top: 60px;}
.need-help{/* position: absolute; */width: 100%;text-align: center;left: 0;bottom: 35px;font-family: 'Satoshi-Medium';}
.start-design1-btn{font-family: 'Satoshi-Medium';}


@media only screen and (min-width:991px) and (max-width:1100px){
.blank-design1 .create-steps {margin-top: 63px;}
}
@media only screen and (max-width:991px){
.upload-steps .hd5{font-size: 25px;}
.blank-design1 .upload-steps {padding: 0 35px;width: 85%;}
.blank-design1 .create-steps {margin-top:8px;}
.upload-steps.upload-steps2.upload-steps3 {width: 100%;}
}
@media only screen and (max-width:767px){
.blank-design1 .upload-steps {padding: 0;width: 100%;}
.blank-design1 .create-steps {margin-top:45px;}
.design-input{margin-right:15px;width: 50%;}
.start-design1{flex-wrap: wrap;}
.label-info{width: 45%;font-size: 12px;padding: 5px 6px 10px;}
.start-design-panel .start-design1-btn {margin-top: 25px;}
.design-container{max-width: 100%;}
}
@media only screen and (max-width:480px){
.label-info::before,.label-info::after{top: 13px;}
.label-info {width:40%;}
.design-input{width: 70%;margin: 0;}
.tooltiptext.label-info::before, .tooltiptext.label-info::after {content:unset}
.tooltip .tooltiptext.label-info{left: -78px;top: -42px;width: 210px;min-width: 100%;}
.start-design1 .tooltip {left: -30px;}
}
/*----tooltip----*/
.tooltip img{max-width:20px;margin-left: 5px;cursor:pointer;}
.tooltip {position: relative;display: inline-block;border-bottom:0;opacity:1;z-index:2;}
.start-design1 .tooltip{left:-30px;}
.tooltip .tooltiptext {visibility: hidden;width: 120px;background-color: black;color: #fff;text-align: center;border-radius: 6px;padding: 5px 0;position: absolute;z-index: 1;bottom: 100%;left: 50%;margin-left: -60px;}
.tooltip:hover .tooltiptext {visibility: visible;}
.tooltip .tooltiptext.label-info{background:#fff;color: #1B5ADE;min-width: 255px;border-radius: 0;text-align: left;padding: 8px 10px;left: 100px;top: -4px;height: 32px;}
.product-listing-r .tooltip .tooltiptext{left: -20px;}
/*----tooltip end----*/

.hd4{color:#1B5ADE;font-family: 'Satoshi-Bold';font-size:17px;margin:0 0 3%;}
.hd5{color:#1B5ADE;font-family: 'Satoshi-Bold';font-size:25px;margin:0 0 3%;}
.select-side-option select{border: 0;background: #fff;padding: 5px;font-family: 'Satoshi-Regular';}
.select-side-option select option{font-family: 'Satoshi-Regular';}
.frnt-bck.select-side-option {padding: 0;}

@media only screen and (min-width:768px){
.start-design-panel{display: flex;flex-wrap: wrap;align-items: center;align-content: space-around;height: 50vh;margin-top: 55px;}
.start-design1{width: 100%;}
.start-design1-btn{width: 100%;}
.need-help{width: 100%;}
.position-absolute{position: absolute;}

}

/*------------------------------------
FONT SIZE
-------------------------------------*/
@media only screen and (min-width:1100px){
body{font-size:1.10vw;line-height: 1.8;}
.upload-steps ul li{font-size:1.3vw;}
.edit-design a{font-size:1.3vw;}/*18px*//*17px*//*14px*/ /*16px*//*15px*/
.tooltip-hd{font-size:1.10vw;}
.safe-area{font-size:0.90vw;}/*12px*/
.hd1{font-size:3vw;}
.txt1{font-size:1.15vw;}
.hd2{font-size:1.6vw;}
.product-desc{font-size:1.03vw;}
.products-list-row .product-desc{font-size:1.2vw;}
.viwe-all a{font-size:1vw;}
.more a{font-size: 1.05vw;}
.next-step a{font-size: 1.10vw;}
.services-hd{font-size:1.7vw;}
#design-your-cards .services-hd{font-size:1.5vw;}
.product-detail-card .services-hd{font-size:1.5vw;}
.product-detail-card .listing.mr1{line-height: 1.5;}
.product-detail-card .listing.mr1 li{background-position:0 7px}
.home-page1 .services-hd{font-size:1.7vw;}
.services-desc{font-size: 1.02vw;line-height: 1.8;}
.read a{font-size:1.06vw;}
.testimonials-txt1{font-size: 2.2vw;}
.testimonials-name{font-size:1.3vw;}
input, select, textarea{font-size:1.10vw;}
.search-text{font-size:3vw;}
.search-box{font-size:1.2vw;}
.search-active .search-box{font-size:1.4vw;}
.h6, h6{font-size: 1.2vw;}
.submenu-in .nav-link{font-size: 1.05vw;}
.menu-div1-in p{font-size: 1.1vw;}
.menu-tg{font-size: 1.1vw;}
.footer-links ul li{font-size: 1.1vw;}
.footer-links ul li li a{font-size:1vw;}
.copyright{font-size:1.03vw;}
.title1{font-size: 3vw;}
.header-txt{font-size:1.4vw;line-height: 1.5;font-family: 'Satoshi-Medium';}
.hd4{font-size:1.3vw;}
.box3 label{font-size:1vw;line-height: 25px;}
.product-listing-box1 .txt3{font-size:1.1vw;}
.tooltip{font-size: 1vw;}
.product-listing-r .services-hd{font-size: 1.5vw;}
.btn{font-size: 1.3vw;font-family: 'Satoshi-Medium';font-weight:500;}
.more a:hover{font-family: 'Satoshi-Medium';font-weight:500;}
.listing li{font-size: 1.1vw;}
.products-list-row .hd2{font-size:1.5vw;}
.social-content a{font-size:1.15vw;}
.price1{font-size:1.25vw;}
a.share:hover{font-size:1.2vw;}
.main-hd{font-size:2.5vw;}
.download-template-li2 li span.area-txt{font-size:1.2vw;}
.download-template-li2 li span.t-1{font-size:1.06vw;}
.upload-steps .hd5{font-size:2.45vw;}
.submit-btn1{font-size:1.06vw;}
.need-help a{font-size:1.1vw;}
.review-txt{font-size:1.3vw;}
.next-cncl .download-pdf a{font-size:1.25vw;}
.hd6{font-size:1.4vw;}
.quantity{font-size:1.2vw;}
.duplicate-edit a{font-size:1vw;}
.remove{font-size:1vw;}
.cart-pro-desc{font-size:1.02vw;}
.continue-btns a{font-size: 1.2vw;}
.ty-tg1{font-size:3vw;}
.ty-tg2{font-size:1.5vw;}
.txt3{font-size:1.5vw;}
.confirm-order-steps2{font-size:1vw;}
.confirm-order-steps ul li{font-size:1.1vw;}
.order-num{font-size:1.3vw;}
.order-summary{font-size:1.3vw;}/*18px*/
.order-address .hd6{font-size:1.25vw;}/*17px*/
.address{font-size: 1.02vw;}/*14px*/
.confirm-order-cart .hd6{font-size: 1.2vw;} /*16px*/
.confirm-order-cart .quantity{font-size: 1.02vw;}
.confirm-order-cart .cart-pro-desc{font-size: 1.02vw;}
.info-txt{font-size:1.10vw;}/*15px*/
.info-txt a.tel{font-size: 1.02vw;}
.confirm-order-cart .price1{font-size:1.10vw;}
.req{font-size:1.5vw;}
.form-address-info .hd6, .form-gst-info .hd6{font-size:1.25vw;}
.upload-btn-wrapper .btn,.upload-btn-wrapper .btn span{font-size: 1.2vw;}
.design-tool-list li a{font-size:1.10vw;}
.viwe-all a,.order a{padding:1.2% 2.5%;}

.product-detail-r .main-hd{font-size:2.2vw;}
.product-detail-r .price1 {font-size: 1vw;font-family: 'Satoshi-Bold';}
.product-detail-r .hd4 {font-size: 1.4vw;}
.hd5 {font-size:2.2vw;}
.order a {font-size:1vw;}
.product-detail-r .product-detail-section2 .txt-1{font-size:1.06vw;padding-right: 15%;line-height: 1.5;}
.accordion > .card .card-header .btn{font-size: 1.2vw;font-family: 'Satoshi-Bold';}
.artwork-guidline-header .breadcrumb-panel ul li a {font-size:1vw;}
.artwork-section1 .hd1-1{font-size:2.5vw;font-family: 'Satoshi-Bold';}
.artwork-section2 .hd1-1{font-size:2.5vw;font-family: 'Satoshi-Bold';}
.artwork-section1 .listing li{font-size: 1vw;}
.artwork-section2 .hd4{font-size:1.15vw;}
}

@media only screen and (min-width:768px){
.frnt-bck{margin-top: 50px;position:relative;z-index: 9;margin-left: -66px;}
.frnt-bck.select-side-option {margin-right: -65px;margin-left:0;padding: 0;}


}
@media only screen and (min-width:768px) and (max-width:1000px){
.frnt-bck.select-side-option{margin-top:0;}
.next-cncl{top: 55px;}

}
@media only screen and (min-width:1100px){
.frnt-bck a {font-size: 1.10vw;}
}
.font-popup .modal-body.review-modal{height:auto;}
@media only screen and (max-width:767px){
.frnt-bck.select-side-option {margin-top: 0;}
}
.modal-body.review-modal {padding: 0;}
.review-screen .design-review-row1{justify-content: center;}
.review-screen .design-review-row1 .carousel-item img{max-height:300px;}
.review-screen .design-review-row1 .carousel-indicators{display:none;}
.design-frnt-bck.frnt-bck.position-a.next-prev-buttons {width: 100%;background: transparent;padding: 0;margin: 0;top: 50%;left: 0;right: 0;padding: 0 6%;}
#threejs_preview {height: 300px;}
.review-screen .next-arrow.btn.btn-primary {float: right;position: absolute;right: 6%;background: url(../../public/images/arrow-dw2.svg) no-repeat 0 0 !important;transform: rotate(-90deg);font-size: 0;border: 0;line-height: 25px;}
.review-screen .prev-arrow.btn.btn-primary {float: left;position: absolute;left: 6%;background: url(../../public/images/arrow-dw2.svg) no-repeat 0 0 !important;font-size: 0;border: 0;transform: rotate(90deg);line-height: 25px;}
.review-screen .prev-arrow.btn.btn-primary:focus,.review-screen .next-arrow.btn.btn-primary:focus{box-shadow: none;}
@media only screen and (min-width:1024px){
.review-screen .next-cncl.w-1 .download-pdf{top:50%;}

}
@media only screen and (min-width: 768px) and (max-width: 991px){
.review-screen .upload-review .design-review{width:100%;}
.design-section.review-screen{padding: 120px 0 0 0;}
.design-frnt-bck.frnt-bck.position-a.next-prev-buttons{padding:0;}
.review-screen .next-cncl.w-1 .download-pdf{top: 85px;}
#threejs_preview .d-block{width:100%;}
#threejs_preview{margin-top:25px;}

}
@media only screen and (max-width:767px){
.design-frnt-bck.frnt-bck.position-a.next-prev-buttons{top:60%;}
.review-screen .prev-arrow.btn.btn-primary{left: 38%;}
.review-screen .next-arrow.btn.btn-primary{right: 38%;}
.modal-body.review-modal .signin-link {position: absolute;right: 20px;top: 30px;}
.review.tabbing .upload-steps, .review .upload-steps {margin-top: -65px;}
.modal-body.review-modal .design-frnt-bck.frnt-bck{top: 140px;left: 70px;}
.modal-body.review-modal .slider-nxt-bck .design-frnt-bck.frnt-bck{top: auto;left: auto;}


}
span.loader {
  width: 48px;
  height: 48px;
  border: 5px dotted #FFF;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 