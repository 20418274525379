/* @import url('http://192.168.4.35:1024/fp-editor-apis-server/fonts.php'); */
/* @import url('https://editor.flexiweb2print.com/dev/fp-editor-apis-server/fonts.php'); */
@import url('https://editor.flexiweb2print.com/fp-editor-apis-server/fonts.php');



/* canvas{
  position: absolute;
  overflow: auto;
} */
/* .middlepane {
  /* height: 500px; */
  /* left: 0; */
  /* position: absolute; */
  /* top: 0; */
  /* width: 500px;  
  overflow: auto;
} */

/* #wrapper {
  /* position: absolute; */
  /* overflow: auto; */
  /* max-height: 70vh;  */
  /* max-width: 800px; 
 } */



.subContainer {
  display: flex;
  flex-direction: row;
  /* overflow: auto; */
  /* background-color: yellow; */
}

.leftpane {
  /* width: 30%; */
  border-collapse: collapse;
}

.addPathImage{
  background-image: url(../../public/images/add_Path-object.svg);
}

.middlepane {
  /* width: 50vh; */
  /* height: 70vh; */
  height: 100%;
  background-color: lightgray;
  z-index: 10;
  border-collapse: collapse;
  display: flex;
  flex-direction: column;
  
}

.addPathImage{
  background-image: url(../../public/images/add_Path-object.svg) !important ;
}

/* canvas{
  position: absolute;
  overflow: auto;
} */
/* .middlepane {
  /* height: 500px; */
  /* left: 0; */
  /* position: absolute; */
  /* top: 0; */
  /* width: 500px;  
  overflow: auto;
} */

/* #wrapper {
  /* position: absolute; */
  /* overflow: auto; */
  /* max-height: 70vh;  */
  /* max-width: 800px; 
 } */


/* .toppane {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  border-collapse: collapse;
} */

/* .canvasStyle {
  width: 50%;
  height: 100%;
  border-color: black;
  border-width: 1;
} */

/* .layerListStyle {
  width: 50%;
  height: 100%;
  border-color: black;
  border-width: 1;
} */

/* .LayerListTableStyle {
   overflow-y:scroll;
   display:block;
   width: 140px;
} */
