#templates {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#templates td, #templates th {
  border: 1px solid #ddd;
  padding: 8px;
}


#templates tr:nth-child(even){background-color: #f2f2f2;}

#templates tr:hover {background-color: #ddd;}

#templates th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #1B5ADE;
  color: white;
}
.text-style{
  font-weight: bold;
  cursor: pointer;
}

.noHeight-overflow{
  height: 100% !important;
  overflow-y: hidden !important;
}

.text-style:hover{
  transform:scale(1.03)
}

.flipImage{
  transform: scaleX(-1);
}

.pointer{
  cursor: pointer;
}
.filter-row1 li {
  cursor: pointer;
  user-select: none;
}



.filter-row2 li {
  margin: 0 2px;
  cursor: pointer;
  background-size: 12px !important;background-position:93% center;
}
/* Loader css */
.loader {
  position: relative;
  text-align: center;
  margin: 15px auto 35px auto;
  z-index: 9999;
  display: block;
  width: 80px;
  height: 80px;
  border: 10px solid rgba(0, 0, 0, .3);
  border-radius: 50%;
  border-top-color: #000;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}


.filter-box{
  display: none;  
  position: absolute;
  z-index: 9999;
  right: 0;
  top: -10px;
  /* overflow: visible; */
  /* background-color: red; */
}
.inner-filter-box{
  width: 520px;
  padding: 10px;
  /* border: 10px solid #1B5ADE; */
}

/* end of loader css */