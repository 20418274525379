.header-panel { background: #fff; color: #0069d9; height: 76px; position: fixed; width: 100%; display: flex; align-items: center; z-index: 9; box-shadow: 1px 6px 11px -8px rgba(14,77,146,0.15); -webkit-box-shadow: 1px 6px 11px -8px rgba(14,77,146,0.15); }
.header-panel .logo {padding:5px 0;}
.header-panel .logo img{max-height: 60px;}
.main-header { z-index: 999; }
.main-content { background: #f7f7f7; padding: 20px 30px 30px 30px; float: left; margin-top:76px; width: 100%; height: calc(100vh - 150px); box-shadow: 5px 0px 15px -9px rgba(0,0,0,0.10) inset; -webkit-box-shadow: 5px 0px 15px -9px rgba(0,0,0,0.10) inset; -moz-box-shadow: 5px 0px 15px -9px rgba(0,0,0,0.10) inset; }
ul.breadcrumb li, ul.breadcrumb li a { display: inline-block; font-size: 14px; font-weight: normal; }
ul.breadcrumb li:after {content: "/";margin: 0 5px;}
ul.breadcrumb li:last-child:after { display: none; } 
ul.breadcrumb { margin-bottom: 0; padding: 5px 0; }
.hd2 { font-size: 25px; color: #000; text-transform: capitalize; font-weight: 500; float: left; margin: 0px 0 24px; width: 100%; padding: 0 0; border-radius: 10px 10px 0 0; text-align: left; position: relative; }
.inside-bg-wrap { background: #fff !important; box-shadow: 0 2px 10px 0 rgb(230 230 230 / 99%); padding: 25px; border-radius: 4px; overflow: hidden; }
.manage-template-wrap { position: relative; }
.catergory-listing { float: left; width: 100%; }
.catergory-row { margin: 0px 0px; display: block; float: left; width: 100%; }
.catergory-clm{float:left;width:20%;padding:0 13px;margin:0 0 30px;}
.catergory-clm-in {width: 100%;border: 1px solid #ddd;text-align: center; border-radius: 5px; overflow:hidden}
.manage-template-wrap .catergory-clm-in { filter: drop-shadow(0 0px 3px #0003); border: 0; border-radius: 0; background: #fff; }
.catergory-hd{width: 100%;color: #2e2e2e; text-transform: uppercase; text-align: left; font-size: 15px; padding: 15px 15px 5px; min-height: 76px; font-weight: 500;}
.catergory-img { display: flex; background: #eee; }
.catergory-img img { display: flex; background: #eee; object-fit: cover; height: 210px; width: 100%; }
.template-edit-del-btns { float: left; width: 100%; text-align: left; padding: 0 15px; margin-bottom: 20px; display: flex; justify-content: space-between; }
.btn-basic { color: #fff !important; background-color: #0069d9; border-color: #0069d9; border: 1px solid transparent; padding: 0.4375rem 1.125rem; font-size: 15px; font-weight: 500; line-height: 1.25rem; border-radius: 4px; min-width: 100px; text-align: center; display: inline-block; transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out; text-decoration: none; background-size: 25px !important; font-family: 'Kumbh Sans', sans-serif; width:100%; }
.btn-basic:hover {text-decoration:none; opacity:0.9;}


.main-content.new-mainwrapper {margin-top:0; height:100vh; padding: 0;}
.modal-3d-preview{float: left;width: 100%;}
.modal-3d-preview-wrap{float: left;width: 100%;display: flex;height:100vh;position: relative;}
.modal-l-editor{float: left; width: 50%;transition:all 0.3s ease-in;position: relative;}
.modal-r-3d-preview{float: left;width:50%;background: #e5f0fb;position: relative;transition:all 0.3s ease-in}
.editor-txt1{color: #000;opacity: 0.73;font-size:15px;line-height:20px;font-family: 'Kumbh Sans', sans-serif;margin-top: 10px;clear: both;}
.editor-wrap1 {float: left;width: 100%;text-align: left;padding:20px;}
.modal-hd2{float: left;width: 100%;letter-spacing: 0.3px;color: #000;font-size:25px;font-weight:500;font-family: 'Kumbh Sans', sans-serif;}
.editor-wrap2 {float: left;width: 100%;text-align: left;padding:0px 20px;background: rgba(112,112,112,0.1);position:relative;height: 67%;}
.front-back-btn {float: left;width: 100%;list-style: none;margin: 15px 0 0 0;padding: 0;}
.front-back-btn li{display:inline-block;padding: 0 10px;margin: 0 0 0 -4px;color: #976E40;text-transform: uppercase;font-size:15px;font-weight:500;font-family: 'Kumbh Sans', sans-serif;}
.front-back-btn li a{color: #000;}
.front-back-btn li.active a{color: #976E40;position: relative;}
.front-back-btn li.active a:after{content:'';border-bottom:2px solid #976E40;position: absolute;width: 100%;left: 0;bottom: -5px;}
.front-back-btn li a:hover{text-decoration:none;}
.add-msg-btn a{color: #976E40;text-transform: uppercase;font-size:14px;font-weight:500;font-family: 'Kumbh Sans', sans-serif;border: 1px solid #976E40;display: inline-block;padding: 10px 30px;margin: 0 0 15px 0;transition:0.3s;}
.add-msg-btn a:hover{text-decoration:none;color: #fff !important;background: #976E40;}
.editor-wrap3 {float: left;width: 100%;text-align: left;padding:14px 30px 5px;}
.editor-wrap3.next-btn {margin-top: -26px;padding-bottom: 0;}
.editor-wrap3.next-btn a {margin-bottom: 0;padding: 8px 30px;background-color: #0069d9;border-color: #0069d9;border: 1px solid transparent;padding: 0.4375rem 1.125rem;font-size: 15px;font-weight: 500;line-height: 1.25rem;border-radius: 4px;min-width: 100px;text-align: center;display: inline-block;transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;text-decoration: none;background-size: 25px !important;font-family: 'Kumbh Sans', sans-serif;color: #fff;}
.editor-wrap3.next-btn a:hover {background:#0069d9 !important;color:#fff !important; opacity: 0.9;}
.open-close-tabs a { color: #0069D9 !important; font-size: 14px; font-weight: 400; font-family: 'Kumbh Sans', sans-serif; border: 1px solid #0069D9; display: inline-block; margin: 0 4px 15px 0; transition: 0.3s; background: #fff; padding: 0.4375rem 1.125rem; border-radius: 5px; line-height: 1.2; }
.open-close-tabs a:hover,.open-close-tabs a.active{text-decoration:none;color: #fff !important;background: #0069D9;}
.open-close-tabs {position: absolute;right: 10px;top: 10px;}
.modal-hd3 {float: left;width: 100%;letter-spacing: 0.27px;color: #0069d9;font-size:18px;font-weight:600;text-align: left;}
.choose-template-wrap{float: left;width: 100%;border-top:1px solid #b7b7b7;padding: 20px;text-align: center;}
.template-list {float: left;width: 100%;margin: 15px 0 0 0;height: 360px;overflow-y: scroll;overflow-x: hidden;scrollbar-color: #707070 #fff;scrollbar-width: thin;scrollbar-color:#707070 #fff;padding-right: 10px;}
.template-img {float: left;width: 50%;padding:0 5px 10px 5px;}
.template-list::-webkit-scrollbar {width:4px;height:5px;background-color:#fff; }
.template-list::-webkit-scrollbar-thumb {background-color:#707070;}
.box-design-panel1 {position: absolute;left: 0;top:30%;border: 1px solid #707070;padding: 5px;background: #fff;}
.box-design-clm {width: 100%;display: block;text-align: center;padding: 10px 0;}
.box-design-clm span {display: block;font-size: 11px;margin: 5px 0 0 0;}
.zoom-panel {position: absolute;right: 10px;bottom: 10px;padding: 20px;}
.threejs .canvas {display: inline-flex;width: 100% !important;}
.editor-text {float: left;width: 100%;padding: 10px 0px;border: 0;text-align: center;background: none; position: absolute;top: 90px;opacity: 1;z-index: 999;}
.editor-text .btn-group, .editor-text .btn-group .btn-group {border-radius: 0;border-bottom: 1px solid #dbdbdb;border-top: 1px solid #dbdbdb;}
.editor-text .btn {border-left: 0;border-right: 1px solid #dbdbdb;border-radius: 0;}
.editor-text .btn-group .btn-group {border: 0;}
.modal-l-editor .object-element-wrap {bottom: 11px;left: 38%;border: 1px solid #dbdbdb;text-align: center;}
.zoom_btn {position: absolute;bottom: 3%;right: 0;width: 9%;text-align: center;display: table;}
.zoom_btn a {  display: inline-flex; margin: 0 5px;}
.modal-l-editor .object-element-wrap {bottom: 11px;left: 35%;border: 1px solid #dbdbdb; text-align: center;width: 30%;box-shadow: none;background: #fff;}
.editor-text a.btn.btn-default.dropdown-toggle {border-left: 1px solid #dbdbdb;}
.template-list .template-img img {width: 100% !important; object-fit: cover; height:170px}



@media only screen and (max-width:767px){
.main-content {height:auto;}
.catergory-clm {width: 100%;}

.choose-template-wrap {padding:15px;}
.modal-l-editor {margin-bottom:30px;}
.modal-3d-preview-wrap{flex-wrap:wrap;height: auto;}
.modal-l-editor,.modal-r-3d-preview{width:100%;}
.modal-r-3d-preview{position:relative;height:50vh;}
.editor-wrap1{padding: 15px 10px;}
.front-back-btn{margin-top:10px;}
.editor-txt1 br{display:none;}


}