.cell {
  flex-Wrap: 'wrap';
  width: 100px;
  padding: 10 10 10 0;
  /* margin-bottom: 5px; */
  background-color: white;
	border: 2px solid white;
  padding-left: 5px;
  padding-right: 5px;
  border-bottom: 2px solid #000;
}

.selectedCell {
  background-color: lightblue;
}
