.main_div {
    height: 100%; 
    width: 100%
}

.split {
    height: 100%;
    width: 50%;
    position: fixed;
    z-index: 1;
    top: 0;
    overflow-x: hidden;
    padding-top: 20px;
  }
  
  .left {
    left: 0;
    background-color: antiquewhite;
    border-color: black;
    border-right: 10px;
  }
  
  .right {
    right: 0;
    background-color:azure;
  }

  /* .canvas {
    background: burlywood;
    height: 500px;
    width: 500px;
  } */
