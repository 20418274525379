
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100000000;
}
.overlay .overlayDoor:before, .overlay .overlayDoor:after {
  content: "";
  position: absolute;
  width: 50%;
  height: 100%;
  background:rgba(0,0,0,0.5);
  transition: 0.5s cubic-bezier(0.77, 0, 0.18, 1);
  transition-delay: 0.8s;
}
.overlay .overlayDoor:before {
  left: 0;
}
.overlay .overlayDoor:after {
  right: 0;
}
.overlay.loaded .overlayDoor:before {
  left: -50%;
}
.overlay.loaded .overlayDoor:after {
  right: -50%;
}
.overlay.loaded .overlayContent {
  opacity: 0;
  margin-top: -15px;
}
.overlay .overlayContent {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: 0.5s cubic-bezier(0.77, 0, 0.18, 1);
}
.overlay .overlayContent .skip {
  display: block;
  width: 130px;
  text-align: center;
  margin: 50px auto 0;
  color: #fff;
  font-weight: 700;
  padding: 12px 0;
  transition: 0.2s ease;
}


.loader {
  width: 128px;
  height: 128px;
  border: 3px solid #fff;
  border-bottom: 3px solid transparent;
  border-radius: 50%;
  position: relative;
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader .inner {
  width: 64px;
  height: 64px;
  border: 3px solid transparent;
  border-top: 3px solid #fff;
  border-radius: 50%;
  -webkit-animation: spinInner 1s linear infinite;
          animation: spinInner 1s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spinInner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-720deg);
  }
}
@keyframes spinInner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-720deg);
  }
}
body {
  background: #eee;
}

.header {
  background: url(../images/spinner-loader.gif) center/cover;
  background-size: cover;
  height: 100vh;
}
.header .darken {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.header .darken h1 {
  font-family: "Nunito";
  font-weight: 700;
  color: #fff;
  margin: 0 0 20px 0;
  text-align: center;
}
.header .darken h1 span {
  font-size: 12px;
  top: -10px;
  position: relative;
}
.header .darken p {
  color: #fff;
  font-family: "Roboto";
  font-weight: 700;
  text-align: center;
  width: 500px;
  margin: 0 auto;
  line-height: 25px;
}

.contentOther {
  background: #fff;
  width: 900px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
}
.contentOther .video {
  height: 484px;
  width: 100%;
  border-radius: 5px;
}
.contentOther h1 {
  font-family: "Roboto";
  margin: 0 0 10px 0;
  font-weight: 400;
}