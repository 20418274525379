/* @import url('http://192.168.4.35:1024/fp-editor-apis-server/fonts.php'); */
/* @import url('http://editor.flexiweb2print.com/dev/fp-editor-apis-server/fonts.php'); */
@import url('https://editor.flexiweb2print.com/fp-editor-apis-server/fonts.php');

/*the container must be positioned relative:*/
/* .custom-select {
  position: relative;
  font-family: Arial;
  width: 200px;
} */

/* .custom-select select {
  display: none; 
} */
/*hide original SELECT element:*/

/* .select-selected {
  background-color: DodgerBlue;
}

.hidden {
  display: none;
}

.show {
  display: inline;
}
/*style the arrow inside the select element:*/
/* .select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
} */

/*point the arrow upwards when the select box is open (active):*/
/* .select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
} */

/*style the items (options), including the selected item:*/
/* .select-items div,.select-selected {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  user-select: none;
} */

/*style items (options):*/
/* .select-items {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
} */

/*hide the items when the select box is closed:*/
/* .select-hide {
  display: none;
}

.select-items div:hover, .same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
} */

.dropdown-menu>li>a:hover{
  background-color: transparent;
}

.focus {
  transition: transform 0.5s;
}
.focus:hover{
  transform:scale(1.2) ;
}
.modal {
  background-color: rgba(0,0,0,0.5); 
}
/* The Modal (background) */
/* Black w/ opacity */
.hidden {
  display: none;
}

.show {
  display: block;

}
.review-modal{
  background:linear-gradient(#E6E6E6, #C6C6C6) !important;
}

/* .fade-out{
  opacity: 0;
  width: 0;
  height: 0;
  transition: width 1s,height 1s 1s, opacity 1s;
}

.fade-in {
  opacity: 1;
  transition: width 0.5s,height 0.5s, opacity 0.5s 0.5s;
} */


/* Image Cropper */
/* .crop-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 80px;
  height: 200px;
  width: 200px;
} */
