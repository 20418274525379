body{margin:0;padding:0;font-family: 'AvenirNext LT Pro';font-size: 14px;line-height: 1.42857;color: #333;font-weight: 400;}
img{max-width:100%;}
.modal-container {float: left;width: 100%;text-align: center;}
.modal-inner-wrap {float: left;width: 100%;padding: 35px;}
.modal-hd {float: left;width: 100%;letter-spacing: 0.36px;color: #976E40;font-size:24px;font-weight:600;font-family:'Avenir Next Demi';}
.modal-contact-wrap {float: left;width: 100%;margin:35px 0 0 0;display: flex;flex-wrap: wrap;}
.modal-l{float:left;width:50%;padding: 50px;border-right: 1px solid #707070;}
.modal-r{float:left;width:50%;padding: 50px;}
.customization-type-img {margin: 0 0 30px;}
.customization-type-hd{letter-spacing: 0.27px;color: #976E40;text-transform: uppercase;font-size:18px;font-weight:600;font-family:'Avenir Next Demi';}
.customization-type-txt {color: #636465;opacity: 0.73;font-size:15px;line-height:20px;font-family: 'Avenir LT Std';margin-top: 10px;white-space: pre-line;}
.customization-type-link {margin-top: 50px;margin-bottom: 15px;}
.customization-type-price{color: #0E7E82;font-size:16px;line-height:19px;font-family: 'Avenir LT Std';}
.w-40{width:40% !important;}
.w-60{width:60% !important; }
.back-arrow {position: absolute;left: 0;top: 45%;}
.back-arrow a {background: #fff;padding:17px 0;float: left;width: 36px;height: 61px;
    margin: 0 0 15px 0 !important;text-align: center;}
.back-arrow img {transform: rotate(-180deg);width: 13px;}
.modal-l-editor .editor-wrap2 .object-element-wrap {bottom: 11px !important;left: 28% !important;
    border: 1px solid #dbdbdb !important;text-align: center !important; width: 47% !important;
    box-shadow: none !important;background: #fff;height: 70px;
    padding: 10px;display: table;padding: 16px 0 0 0 !important;margin: 0 auto;}
.editor-wrap2 .object-element-wrap .object-element-clm {display: inline-flex;width: 25%;align-content: center;flex-direction: row-reverse;
flex-wrap: wrap;vertical-align: middle;}
.modal-l-editor .editor-wrap2 .object-element-wrap ul.dropdown-menu li {height: 90px !important;}
.zoom_btn a {margin-bottom: 15px;}
.editor-text .btn-group .btn-group-inside-1 {width: 125px;border-left: 1px solid #dbdbdb;text-align: left;}
.editor-text .btn-group .btn-group-inside-1 a.btn.btn-default.dropdown-toggle {border-left: 0;width: 100%;text-align: left;}
.editor-text .btn-group .btn-group-inside-1 .dropdown-toggle::after {right: 9px !important;left: auto;position: absolute;top: 15px;box-sizing: border-box;height: 8px;width: 8px;
    border-style: solid;border-color: black;border-width: 0px 1px 1px 0px;transform: rotate(45deg);transition: border-width 150ms ease-in-out;}
.editor-text .btn-group .btn-group-inside-3 a.btn.btn-default.dropdown-toggle.btn-1::after {right: 7px !important;left: auto;position: absolute;top: 11px;box-sizing: border-box;height: 8px;width: 8px;
    border-style: solid;border-color: black;border-width: 0px 1px 1px 0px;transform: rotate(45deg);transition: border-width 150ms ease-in-out;}
.editor-text .btn-group .btn-group-inside-3 a.btn.btn-default.dropdown-toggle.btn-1 {width: 51px !important;
padding-left: 5px;padding-right: 5px;text-align: left;padding-top: 2px;position: relative;    border-bottom: 0;
border-left: 0;border-top: 0;}
.editor-text .btn-group .btn-group-inside-2 {width: 77px;display: flex;flex-wrap: wrap;justify-content: space-between;}
.editor-text .btn-group .btn-group-inside-2 span, .editor-text .btn-group .btn-group-inside-2 img {float: left;}
.editor-text .btn-group .btn-group-inside-2 img {margin:3px 10px;max-width: 27px !important;}

.editor-text .btn-group .btn-group-inside-3 img {
    max-width: 16px !important;
}

.editor-text .btn-group .btn-group-inside-2 a {padding-left: 2px;padding-right: 2px;width: 100%;text-align: center;}
.editor-text .btn-group a.btn.btn-default:active, .editor-text a.btn.btn-default:focus {border-color: #dbdbdb;}
.upload-image-modal button   {  text-transform: uppercase;font-size: 14px;font-weight: 500;font-family: 'Avenir LT Std';border: 1px solid #976E40;
display: inline-block;padding: 10px 30px;margin: 0 0 15px 0;transition: 0.3s;background: #fff;color: #976E40;}
.upload-image-modal button:hover {background: #976E40;color: #fff;}
.upload-image-modal input.btn-basic, .upload-image-modal .modal-footer button.btn.btn-primary {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Avenir LT Std';
    border: 1px solid #976E40;
    display: inline-block;
    padding: 10px 30px;
    margin: 0 0 15px 0;
    transition: 0.3s;
    background: #fff;
    border-radius: 0;
    color: #976E40;
    margin-top: 20px;
}
.modal-l-editor.left-panel-2.w-40 {
    overflow: hidden;
}
.template-img.active img {
    border: 2px solid #976E40;
}

@media screen and (min-width:768px) and (max-width:992px) {
.modal-l,.modal-r{padding:30px;}

}
@media screen and (max-width:767px) {
.modal-inner-wrap{padding: 20px 5px;}
.modal-l,.modal-r{padding: 10px;}
.customization-type-hd{font-size: 14px;}
.customization-type-txt{font-size: 13px;min-height: 110px;}
.customization-type-txt br{display:none;}
.customization-type-link {margin-top: 15px;}
.w-40{width:100% !important;}
.w-60{width:100% !important;}
}

/* 3d-preview */

.modal-3d-preview{float: left;width: 100%;}
.modal-3d-preview-wrap{float: left;width: 100%;display: flex;height:100vh;position: relative;}
.modal-l-editor{float: left; width: 50%;transition:all 0.3s ease-in;position: relative;}
.modal-r-3d-preview{float: left;width:50%;background: #eec5c5;position: relative;transition:all 0.3s ease-in}
.editor-txt1{color: #636465;opacity: 0.73;font-size:15px;line-height:20px;font-family: 'Avenir LT Std';margin-top: 10px;}
.editor-wrap1 {float: left;width: 100%;text-align: center;padding:8px 30px;}
.modal-hd2{float: left;width: 100%;letter-spacing: 0.3px;color: #976E40;font-size:20px;font-weight:500;font-family: 'Avenir LT Std';}
.editor-wrap2 {float: left;width: 100%;text-align: center;padding:0px 20px;background: rgba(112,112,112,0.1);position:relative;height: 67%;}
.front-back-btn {float: left;width: 100%;list-style: none;margin: 15px 0 0 0;padding: 0;}
.front-back-btn li{display:inline-block;padding: 0 10px;margin: 0 0 0 -4px;color: #976E40;text-transform: uppercase;font-size:15px;font-weight:500;font-family: 'Avenir LT Std';}
.front-back-btn li a{color: #000;}
.front-back-btn li.active a{color: #976E40;position: relative;}
.front-back-btn li.active a:after{content:'';border-bottom:2px solid #976E40;position: absolute;width: 100%;left: 0;bottom: -5px;}
.front-back-btn li a:hover{text-decoration:none;}
.add-msg-btn a{color: #976E40;text-transform: uppercase;font-size:14px;font-weight:500;font-family: 'Avenir LT Std';border: 1px solid #976E40;display: inline-block;padding: 10px 30px;margin: 0 0 15px 0;transition:0.3s;}
.add-msg-btn a:hover{text-decoration:none;color: #fff !important;background: #976E40;}
.editor-wrap3 {float: left;width: 100%;text-align: center;padding:14px 30px 5px;}
.editor-wrap3.next-btn {margin-top: -26px;padding-bottom: 0;}
.editor-wrap3.next-btn a {color:#976E40 !important;margin-bottom: 0;padding: 8px 30px;}
.editor-wrap3.next-btn a:hover {background:#976E40 !important;color:#fff !important}
.open-close-tabs a{color: #976E40;text-transform: uppercase;font-size:14px;font-weight:500;font-family: 'Avenir LT Std';border: 1px solid #976E40;display: inline-block;padding: 10px 30px;margin: 0 4px 15px 0;transition:0.3s;background:#fff;}
.open-close-tabs a:hover,.open-close-tabs a.active{text-decoration:none;color: #fff !important;background: #976E40;}
.open-close-tabs {position: absolute;right: 10px;top: 10px;}
.modal-hd3 {float: left;width: 100%;letter-spacing: 0.27px;color: #976E40;font-size:18px;font-weight:600;font-family:'Avenir Next Demi';text-align: center;}
.choose-template-wrap{float: left;width: 100%;border-top:1px solid #b7b7b7;padding: 20px;text-align: center;}
.template-list {float: left;width: 100%;margin: 15px 0 0 0;height: 340px;overflow-y: scroll;overflow-x: hidden;scrollbar-color: #707070 #fff;scrollbar-width: thin;scrollbar-color:#707070 #fff;padding-right: 10px;}
.template-img {float: left;width: 50%;padding:0 5px 10px 5px;}
.template-list::-webkit-scrollbar {width:4px;height:5px;background-color:#fff; }
.template-list::-webkit-scrollbar-thumb {background-color:#707070;}
.box-design-panel1 {position: absolute;left: 0;top:30%;border: 1px solid #707070;padding: 5px;background: #fff;}
.box-design-clm {width: 100%;display: block;text-align: center;padding: 10px 0;}
.box-design-clm span {display: block;font-size: 11px;margin: 5px 0 0 0;}
.zoom-panel {position: absolute;right: 10px;bottom: 10px;padding: 20px;}
.threejs .canvas {display: inline-flex;width: 100% !important;}
.editor-text {float: left;width: 100%;padding: 10px 0px;border: 0;text-align: center;background: none;
    position: absolute;top: 90px;opacity: 1;z-index: 999;}
.editor-text .btn-group, .editor-text .btn-group .btn-group {border-radius: 0;border-bottom: 1px solid #dbdbdb;border-top: 1px solid #dbdbdb;}
.editor-text .btn {border-left: 0;border-right: 1px solid #dbdbdb;border-radius: 0;}
.editor-text .btn-group .btn-group {border: 0;}
.modal-l-editor .object-element-wrap {bottom: 11px;left: 38%;border: 1px solid #dbdbdb;text-align: center;}
.zoom_btn {position: absolute;bottom: 3%;right: 0;width: 9%;text-align: center;display: table;}
.zoom_btn a {  display: inline-flex; margin: 0 5px;}
.modal-l-editor .object-element-wrap {bottom: 11px;left: 35%;border: 1px solid #dbdbdb;
    text-align: center;width: 30%;box-shadow: none;background: #fff;}
.editor-text a.btn.btn-default.dropdown-toggle {border-left: 1px solid #dbdbdb;}
.template-list .template-img img {width: 100% !important;}
.editor-wrap3 a {color: #976E40 !important;}

@media screen and (max-width:767px) {
.modal-3d-preview-wrap{flex-wrap:wrap;height: auto;}
.modal-l-editor,.modal-r-3d-preview{width:100%;}
.modal-r-3d-preview{position:relative;height:50vh;}
.editor-wrap1{padding: 15px 10px;}
.front-back-btn{margin-top:10px;}
.editor-txt1 br{display:none;}






}




@font-face {
    font-family: 'AvenirNext LT Pro';
    src: url('../../../public/fonts/PCJFonts/AvenirNextLTPro-Regular.eot');
    src: url('../../../public/fonts/PCJFonts/AvenirNextLTPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-Regular.woff2') format('woff2'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-Regular.woff') format('woff'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-Regular.ttf') format('truetype'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-Regular.svg#AvenirNextLTPro-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next Demi';
    src: url('../../../public/fonts/PCJFonts/AvenirNext-DemiBold.eot');
    src: url('../../../public/fonts/PCJFonts/AvenirNext-DemiBold.eot?#iefix') format('embedded-opentype'),
        url('../../../public/fonts/PCJFonts/AvenirNext-DemiBold.woff2') format('woff2'),
        url('../../../public/fonts/PCJFonts/AvenirNext-DemiBold.woff') format('woff'),
        url('../../../public/fonts/PCJFonts/AvenirNext-DemiBold.ttf') format('truetype'),
        url('../../../public/fonts/PCJFonts/AvenirNext-DemiBold.svg#AvenirNext-DemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AvenirNext LT Pro';
    src: url('../../../public/fonts/PCJFonts/AvenirNextLTPro-Bold.eot');
    src: url('../../../public/fonts/PCJFonts/AvenirNextLTPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-Bold.woff2') format('woff2'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-Bold.woff') format('woff'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-Bold.ttf') format('truetype'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-Bold.svg#AvenirNextLTPro-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir LT Std';
    src: url('../../../public/fonts/PCJFonts/AvenirLTStd-Light.eot');
    src: url('../../../public/fonts/PCJFonts/AvenirLTStd-Light.eot?#iefix') format('embedded-opentype'),
        url('../../../public/fonts/PCJFonts/AvenirLTStd-Light.woff2') format('woff2'),
        url('../../../public/fonts/PCJFonts/AvenirLTStd-Light.woff') format('woff'),
        url('../../../public/fonts/PCJFonts/AvenirLTStd-Light.ttf') format('truetype'),
        url('../../../public/fonts/PCJFonts/AvenirLTStd-Light.svg#AvenirLTStd-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next LT Pro';
    src: url('../../../public/fonts/PCJFonts/AvenirNextLTPro-BoldCn.eot');
    src: url('../../../public/fonts/PCJFonts/AvenirNextLTPro-BoldCn.eot?#iefix') format('embedded-opentype'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-BoldCn.woff2') format('woff2'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-BoldCn.woff') format('woff'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-BoldCn.ttf') format('truetype'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-BoldCn.svg#AvenirNextLTPro-BoldCn') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next LT Pro';
    src: url('../../../public/fonts/PCJFonts/AvenirNextLTPro-Bold_1.eot');
    src: url('../../../public/fonts/PCJFonts/AvenirNextLTPro-Bold_1.eot?#iefix') format('embedded-opentype'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-Bold_1.woff2') format('woff2'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-Bold_1.woff') format('woff'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-Bold_1.ttf') format('truetype'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-Bold_1.svg#AvenirNextLTPro-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir LT Std';
    src: url('../../../public/fonts/PCJFonts/AvenirLTStd-Medium.eot');
    src: url('../../../public/fonts/PCJFonts/AvenirLTStd-Medium.eot?#iefix') format('embedded-opentype'),
        url('../../../public/fonts/PCJFonts/AvenirLTStd-Medium.woff2') format('woff2'),
        url('../../../public/fonts/PCJFonts/AvenirLTStd-Medium.woff') format('woff'),
        url('../../../public/fonts/PCJFonts/AvenirLTStd-Medium.ttf') format('truetype'),
        url('../../../public/fonts/PCJFonts/AvenirLTStd-Medium.svg#AvenirLTStd-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir LT Std';
    src: url('../../../public/fonts/PCJFonts/AvenirLTStd-Roman.eot');
    src: url('../../../public/fonts/PCJFonts/AvenirLTStd-Roman.eot?#iefix') format('embedded-opentype'),
        url('../../../public/fonts/PCJFonts/AvenirLTStd-Roman.woff2') format('woff2'),
        url('../../../public/fonts/PCJFonts/AvenirLTStd-Roman.woff') format('woff'),
        url('../../../public/fonts/PCJFonts/AvenirLTStd-Roman.ttf') format('truetype'),
        url('../../../public/fonts/PCJFonts/AvenirLTStd-Roman.svg#AvenirLTStd-Roman') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next LT Pro';
    src: url('../../../public/fonts/PCJFonts/AvenirNextLTPro-BoldCnIt.eot');
    src: url('../../../public/fonts/PCJFonts/AvenirNextLTPro-BoldCnIt.eot?#iefix') format('embedded-opentype'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-BoldCnIt.woff2') format('woff2'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-BoldCnIt.woff') format('woff'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-BoldCnIt.ttf') format('truetype'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-BoldCnIt.svg#AvenirNextLTPro-BoldCnIt') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir LT Std';
    src: url('../../../public/fonts/PCJFonts/AvenirLTStd-Book.eot');
    src: url('../../../public/fonts/PCJFonts/AvenirLTStd-Book.eot?#iefix') format('embedded-opentype'),
        url('../../../public/fonts/PCJFonts/AvenirLTStd-Book.woff2') format('woff2'),
        url('../../../public/fonts/PCJFonts/AvenirLTStd-Book.woff') format('woff'),
        url('../../../public/fonts/PCJFonts/AvenirLTStd-Book.ttf') format('truetype'),
        url('../../../public/fonts/PCJFonts/AvenirLTStd-Book.svg#AvenirLTStd-Book') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next LT Pro';
    src: url('../../../public/fonts/PCJFonts/AvenirNextLTPro-Demi.eot');
    src: url('../../../public/fonts/PCJFonts/AvenirNextLTPro-Demi.eot?#iefix') format('embedded-opentype'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-Demi.woff2') format('woff2'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-Demi.woff') format('woff'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-Demi.ttf') format('truetype'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-Demi.svg#AvenirNextLTPro-Demi') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next LT Pro';
    src: url('../../../public/fonts/PCJFonts/AvenirNextLTPro-CnIt.eot');
    src: url('../../../public/fonts/PCJFonts/AvenirNextLTPro-CnIt.eot?#iefix') format('embedded-opentype'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-CnIt.woff2') format('woff2'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-CnIt.woff') format('woff'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-CnIt.ttf') format('truetype'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-CnIt.svg#AvenirNextLTPro-CnIt') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next LT Pro';
    src: url('../../../public/fonts/PCJFonts/AvenirNextLTPro-HeavyCn.eot');
    src: url('../../../public/fonts/PCJFonts/AvenirNextLTPro-HeavyCn.eot?#iefix') format('embedded-opentype'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-HeavyCn.woff2') format('woff2'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-HeavyCn.woff') format('woff'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-HeavyCn.ttf') format('truetype'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-HeavyCn.svg#AvenirNextLTPro-HeavyCn') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next LT Pro';
    src: url('../../../public/fonts/PCJFonts/AvenirNextLTPro-DemiCn.eot');
    src: url('../../../public/fonts/PCJFonts/AvenirNextLTPro-DemiCn.eot?#iefix') format('embedded-opentype'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-DemiCn.woff2') format('woff2'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-DemiCn.woff') format('woff'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-DemiCn.ttf') format('truetype'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-DemiCn.svg#AvenirNextLTPro-DemiCn') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next LT Pro';
    src: url('../../../public/fonts/PCJFonts/AvenirNextLTPro-DemiCnIt.eot');
    src: url('../../../public/fonts/PCJFonts/AvenirNextLTPro-DemiCnIt.eot?#iefix') format('embedded-opentype'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-DemiCnIt.woff2') format('woff2'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-DemiCnIt.woff') format('woff'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-DemiCnIt.ttf') format('truetype'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-DemiCnIt.svg#AvenirNextLTPro-DemiCnIt') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next LT Pro';
    src: url('../../../public/fonts/PCJFonts/AvenirNextLTPro-MediumCnIt.eot');
    src: url('../../../public/fonts/PCJFonts/AvenirNextLTPro-MediumCnIt.eot?#iefix') format('embedded-opentype'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-MediumCnIt.woff2') format('woff2'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-MediumCnIt.woff') format('woff'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-MediumCnIt.ttf') format('truetype'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-MediumCnIt.svg#AvenirNextLTPro-MediumCnIt') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next LT Pro';
    src: url('../../../public/fonts/PCJFonts/AvenirNextLTPro-Regular_1.eot');
    src: url('../../../public/fonts/PCJFonts/AvenirNextLTPro-Regular_1.eot?#iefix') format('embedded-opentype'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-Regular_1.woff2') format('woff2'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-Regular_1.woff') format('woff'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-Regular_1.ttf') format('truetype'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-Regular_1.svg#AvenirNextLTPro-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next LT Pro';
    src: url('../../../public/fonts/PCJFonts/AvenirNextLTPro-It.eot');
    src: url('../../../public/fonts/PCJFonts/AvenirNextLTPro-It.eot?#iefix') format('embedded-opentype'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-It.woff2') format('woff2'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-It.woff') format('woff'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-It.ttf') format('truetype'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-It.svg#AvenirNextLTPro-It') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next LT Pro';
    src: url('../../../public/fonts/PCJFonts/AvenirNextLTPro-MediumCn.eot');
    src: url('../../../public/fonts/PCJFonts/AvenirNextLTPro-MediumCn.eot?#iefix') format('embedded-opentype'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-MediumCn.woff2') format('woff2'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-MediumCn.woff') format('woff'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-MediumCn.ttf') format('truetype'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-MediumCn.svg#AvenirNextLTPro-MediumCn') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next LT Pro';
    src: url('../../../public/fonts/PCJFonts/AvenirNextLTPro-DemiIt.eot');
    src: url('../../../public/fonts/PCJFonts/AvenirNextLTPro-DemiIt.eot?#iefix') format('embedded-opentype'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-DemiIt.woff2') format('woff2'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-DemiIt.woff') format('woff'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-DemiIt.ttf') format('truetype'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-DemiIt.svg#AvenirNextLTPro-DemiIt') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next LT Pro';
    src: url('../../../public/fonts/PCJFonts/AvenirNextLTPro-HeavyCnIt.eot');
    src: url('../../../public/fonts/PCJFonts/AvenirNextLTPro-HeavyCnIt.eot?#iefix') format('embedded-opentype'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-HeavyCnIt.woff2') format('woff2'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-HeavyCnIt.woff') format('woff'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-HeavyCnIt.ttf') format('truetype'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-HeavyCnIt.svg#AvenirNextLTPro-HeavyCnIt') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next LT Pro';
    src: url('../../../public/fonts/PCJFonts/AvenirNextLTPro-Cn.eot');
    src: url('../../../public/fonts/PCJFonts/AvenirNextLTPro-Cn.eot?#iefix') format('embedded-opentype'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-Cn.woff2') format('woff2'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-Cn.woff') format('woff'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-Cn.ttf') format('truetype'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-Cn.svg#AvenirNextLTPro-Cn') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next LT Pro';
    src: url('../../../public/fonts/PCJFonts/AvenirNextLTPro-UltLtCnIt.eot');
    src: url('../../../public/fonts/PCJFonts/AvenirNextLTPro-UltLtCnIt.eot?#iefix') format('embedded-opentype'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-UltLtCnIt.woff2') format('woff2'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-UltLtCnIt.woff') format('woff'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-UltLtCnIt.ttf') format('truetype'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-UltLtCnIt.svg#AvenirNextLTPro-UltLtCnIt') format('svg');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next LT Pro';
    src: url('../../../public/fonts/PCJFonts/AvenirNextLTPro-UltLtCn.eot');
    src: url('../../../public/fonts/PCJFonts/AvenirNextLTPro-UltLtCn.eot?#iefix') format('embedded-opentype'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-UltLtCn.woff2') format('woff2'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-UltLtCn.woff') format('woff'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-UltLtCn.ttf') format('truetype'),
        url('../../../public/fonts/PCJFonts/AvenirNextLTPro-UltLtCn.svg#AvenirNextLTPro-UltLtCn') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}